<template>
  <div class="page-login">
    <video
      class="video"
      src="@/assets/video/login.mp4"
      autoplay="true"
      loop="true"
      muted="true"
    />
    <div class="v-middle">
      <form class="v-middle-content" @submit.prevent="onLogin">
        <div class="header flex">
          <img
            class="login-logo"
            src="../assets/images/logo.png"
          >
          <div class="title cell">
            <h2>纵购公众号管理</h2>
            <p>极致简介&emsp;高效办公</p>
          </div>
        </div>
        <yos-input
          icon="icon-user"
          type="text"
          :width="300"
          class="yos-input-big"
          placeholder="请输入客户编号或联系手机"
          v-model="username"
        />
        <yos-input
          icon="icon-password"
          type="password"
          :width="300"
          class="yos-input-big"
          placeholder="请输入密码"
          v-model="password"
        />
        <yos-button type="submit" class="yos-button-primary">登录</yos-button>
      </form>
    </div>
  </div>
</template>

<script>
import YosInput from '../components/form/yos-input'
import YosButton from '../components/form/yos-button'
import { ref, onBeforeUnmount } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import { useServer } from '../hook'
import {
  getToken
} from '../data'
import {
  setFullScreen,
  setAccessToken,
  setLocalUser
} from '../util'

export default {
  name: 'Login',
  components: {
    YosInput,
    YosButton
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const username = ref('')
    const password = ref('')
    let formRoute

    onBeforeRouteUpdate((to, from, next) => {
      formRoute = from.name === 'lock' ? '/' : from
      next()
    })

    setFullScreen(true)

    const setTokenParams = useServer(getToken, ({ user, token }) => {
      const rights = user && user.rights || []
      setAccessToken(token)
      setLocalUser(user)

      if (rights.length <= 0) {
        router.replace('/lock')
        return
      }

      const formIsRight = rights.find(item => item.method === 'GET' && formRoute && item.path === formRoute.path)

      if (!formIsRight) {
        formRoute = (rights.find(item => item.type === 1 || (item.method === 'GET' && !/^\/api\//.test(item.path))) || {}).path
      }

      router.replace(formRoute)
    })

    const onLogin = async () => {
      const referrer = decodeURIComponent(route.query.referrer || '')
      const path = referrer.split('?')[0]

      setTokenParams({username: username.value, password: password.value})
    }

    onBeforeUnmount(() => {
      setFullScreen(false)
    })

    return {
      username,
      password,
      onLogin
    }
  }
}
</script>

<style lang="postcss">
.page-login {
  padding: 0 !important;
  position: relative;
  z-index: 10;
  height: 100%;

  .video {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .v-middle {
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .v-middle-content {
    background-color: rgba(255, 255, 255, .8);
    border-radius: 10px;
    padding: 30px 100px;
    width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }

  .login-logo {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }

  .header {
    text-align: left;
    margin-bottom: 10px;

    h2 {
      font-weight: normal;
      font-size: 26px;
    }

    p {
      color: #999;
      font-size: 12px;
    }
  }

  .yos-input {
    margin-top: 10px;
  }

  .yos-button {
    width: 100%;
    margin: 50px 0 20px 0;
  }
}
</style>
